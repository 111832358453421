import React from "react";
import { SiteHeader } from "../../Components/SiteHeader";
import ROUTES, { RenderRoutes } from "../../Routes/Routes";

/**
 * The top level screen controlling the sub-screens.
 */
export const TopLevelScreen = () => {
	const currentDate = new Date();

	return (
		<div>
			<SiteHeader />
			<RenderRoutes
				routes={ROUTES}
			/>

			<footer className="container py-5">
				<div className="row">
					<div className="col-12 col-md">
						<small className="d-block mb-3 text-muted">{"Copyright Simon Cantan " + (currentDate.getFullYear() !== 2022 ? "2022-" : "") + currentDate.getFullYear()}</small>
					</div>
				</div>
			</footer>
		</div>
	);
};
