import React from "react";

/**
 * Lets users donate.
 */
export const DonateScreen = () => {
	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
				<div className="col-md-5 p-lg-5 mx-auto my-5">
					<h1 className="display-5 font-weight-normal pb-3">Donate</h1>
					<p>
						Any donations help me make Story Breaker better. Thanks for donating!
					</p>
					<form
						action="https://www.paypal.com/donate"
						method="post"
						target="_top"
					>
						<input
							type="hidden"
							name="hosted_button_id"
							value="ZWTS3TUPTSMQ6"
						/>
						<input
							type="image"
							src="https://www.paypalobjects.com/en_US/NO/i/btn/btn_donateCC_LG.gif"
							// border="0"
							name="submit"
							title="PayPal - The safer, easier way to pay online!"
							alt="Donate with PayPal button"
						/>
						<img
							alt=""
							// border="0"
							src="https://www.paypal.com/en_NO/i/scr/pixel.gif"
							width="1"
							height="1"
						/>
					</form>
				</div>
			</div>
		</>
	);
};
