import React from "react";
import "./App.scss";
import { TopLevelScreen } from "./Screens/TopLevelScreen/TopLevelScreen";

/**
 * The main application.
 */
function App () {
	return (
		<TopLevelScreen />
	);
}

export default App;
