import React from "react";
import PropTypes from "prop-types";

/**
 * Embeds a YouTube video.
 */
const YoutubeEmbed = ({ embedId, }: { embedId: string }) => (
	<div className="video-responsive">
		<iframe
			width="420"
			height="300"
			src={`https://www.youtube.com/embed/${embedId}`}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			title="Getting Started"
		/>
	</div>
);

YoutubeEmbed.propTypes = {
	embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
