import React from "react";
import { Link } from "react-router-dom";

/**
 * The welcome screen for the system
 */
export const HomeScreen = () => {
	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
				<div className="col-md-8 p-lg-4 mx-auto my-5">
					<h1 className="display-3 font-weight-normal">Novel Toolkit</h1>
					<h1 className="display-5 font-weight-normal">First Class Tools for Writers</h1>
					<p className="lead font-weight-normal">
						Tools for helping you create your worlds.
					</p>
					<p>
						Welcome to Novel Toolkit, where I share the tools I've made for my own writing. I hope you find them useful.
						The tools are designed to save you time and money, so you can focus on what matters most: writing.
					</p>
					<p>
						All of the main tools are completely free, and you can use them as much as you want.
					</p>

					<hr />
					<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
						<div className="bg-dark mr-md-3 p-3 text-center text-white overflow-hidden">
							<div className="my-3 py-3">
								<h2 className="display-5">Story Breaker</h2>
								<p className="lead">
									Story Breaker is a completely free desktop application for world building, outlining, and writing a first drafts.
									Everything is saved to your computer, no websites, subscriptions, or licenses are required.
								</p>
							</div>
							<div
								className="bg-light box-shadow mx-auto"
								style={{ width: 750, }}
							>
								<img
									alt="Story Breaker"
									src="/images/StoryBreaker.png"
									width="100%"
								/>
							</div>
							<div className="my-3 py-3">
								<p>
									Click the link below to find out more.
								</p>
								<Link
									to="/storybreaker"
								>
									<span
										className="btn btn-outline-secondary"
									>
										Story Breaker
									</span>
								</Link>
							</div>
							<div className="my-3 py-3">
								<p>
									Or download it here:
								</p>
								<a href={"products/story-breaker/story-breaker-setup.exe"}>
									Windows
								</a>
							</div>
						</div>
					</div>

					<hr />
					<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
						<div className="bg-dark mr-md-3 p-3 text-center text-white overflow-hidden">
							<div className="my-3 py-3">
								<h2 className="display-5">Site Lightning</h2>
								<p className="lead">
									Site Lightning lets you create an author website that you can upload to any web host. The website is created based
									on configuration files that you can edit in a text editor. This means you can create a website that is unique to you.
								</p>
							</div>
							<div
								className="bg-light box-shadow mx-auto"
								style={{ width: 750, }}
							>
								<img
									alt="Site Lightning"
									src="/images/SiteLightning.png"
									width="100%"
								/>
							</div>
							<div className="my-3 py-3">
								<p>
									Click the link below to find out more.
								</p>
								<Link
									to="/sitelightning"
								>
									<span
										className="btn btn-outline-secondary"
									>
										Site Lightning
									</span>
								</Link>
							</div>
						</div>
					</div>

				</div>
				<div className="product-device box-shadow d-none d-md-block" />
				<div className="product-device product-device-2 box-shadow d-none d-md-block" />
			</div>
		</>
	);
};
