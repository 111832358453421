import React from "react";
import { Route, Routes } from "react-router-dom";
import { AboutScreen } from "../Screens/AboutScreen/AboutScreen";
import { DonateScreen } from "../Screens/DonateScreen/DonateScreen";
import { StoryBreakerScreen } from "../Screens/StoryBreakerScreen/StoryBreakerScreen";
import { SiteLightningScreen } from "../Screens/SiteLightningScreen/SiteLightningScreen";
import { LegalScreen } from "../Screens/LegalScreen/LegalScreen";
import { TutorialScreen } from "../Screens/TutorialScreen/TutorialScreen";
import { HomeScreen } from "./../Screens/HomeScreen/HomeScreen";

const ROUTES = [
	{
		element: <HomeScreen />,
		exact: true,
		key: "Home",
		path: "/",
	},
	{
		element: <StoryBreakerScreen />,
		exact: true,
		key: "Story Breaker",
		path: "/storybreaker",
	},
	{
		element: <SiteLightningScreen />,
		exact: true,
		key: "Site Lightning",
		path: "/sitelightning",
	},
	{
		element: <AboutScreen />,
		exact: true,
		key: "About",
		path: "/about",
	},
	{
		element: <LegalScreen />,
		exact: true,
		key: "Legal",
		path: "/legal",
	},
	{
		element: <DonateScreen />,
		exact: true,
		key: "Donate",
		path: "/donate",
	},
	{
		element: <TutorialScreen />,
		exact: true,
		key: "Tutorials",
		path: "/tutorial",
	}
];

export default ROUTES;

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes ({ routes, }) {
	return (
		<Routes>
			{routes.map((route, i) => {
				return (
					<Route
						key={route.key}
						{...route}
					/>
				);
			})}
			<Route component={() => <h1>Not Found!</h1>} />
		</Routes>
	);
}
