import React, { useEffect } from "react";

/**
 * Lets the user download the app.
 */
export const StoryBreakerScreen = () => {
	const [ latestVersion, setLatestVersion ] = React.useState<any>(null);

	useEffect(() => {
		loadLatestDownload();
	}, []);

	/**
	 * Gets the latest download of the app.
	 */
	async function loadLatestDownload () {
		const response = await fetch("products/story-breaker/current-release.json")
			.then((r) => r.json());

		setLatestVersion(response);
	}

	if (!latestVersion) {
		return (
			<i className="fas fa-spinner fa-spin fa-2x fa-fw my-2" />
		);
	}

	const {
		versionNumber,
	} = latestVersion;

	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-1 m-md-3 text-center bg-light">
				<div className="col-md-5 p-lg-5 mx-auto my-5">
					<h1 className="display-3 font-weight-normal">Story Breaker</h1>
					<h1 className="display-5 font-weight-normal">First Idea to First Draft</h1>
					<p className="lead font-weight-normal">
						Gets you from your initial idea, all the way to your first draft.
					</p>
					<p>
						Story Breaker is a completely free desktop application for world building, outlining, and writing a first drafts.
						Everything is saved to your computer, no websites, subscriptions, or licenses are required.
					</p>
					<p>
						The only paid part of the whole application is the optional AI Co-Author. Which is not required to world build, outline or write.
						Everything else is 100% free.
					</p>
					<p className="lead font-weight-normal">Click the link below to download Story Breaker.</p>
					<p className="lead font-weight-normal">Version {versionNumber}</p>
					<a href={"products/story-breaker/story-breaker-setup.exe"}>
						Windows
					</a>
					<br />
					<br />
					<small>
						Story Breaker is currently in beta. Please let me know if you find any bugs by using the feedback link in the header.
					</small>
				</div>
				<div className="product-device box-shadow d-none d-md-block" />
				<div className="product-device product-device-2 box-shadow d-none d-md-block" />
			</div>

			<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
				<div className="bg-dark mr-md-3 p-3 text-center text-white overflow-hidden">
					<div className="my-3 py-3">
						<h2 className="display-5">World Building</h2>
						<p className="lead">Create your characters, locations, and much more.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="World Building"
							src="/images/WorldBuilding.png"
							width="100%"
						/>
					</div>
				</div>
				<div className="bg-light mr-md-3 p-3 text-center overflow-hidden">
					<div className="my-3 p-3">
						<h2 className="display-5">Outlining</h2>
						<p className="lead">Use multiple outlining methods to come up with your story.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="Outlining"
							src="/images/Outlining.png"
							width="100%"
						/>
					</div>
				</div>
			</div>

			<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
				<div className="bg-light mr-md-3 p-3 text-center overflow-hidden">
					<div className="my-3 p-3">
						<h2 className="display-5">First Draft</h2>
						<p className="lead">Write using blocks to speed you through to your first draft.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="First Drafting"
							src="/images/FirstDrafting.png"
							width="100%"
						/>
					</div>
				</div>
				<div className="bg-dark mr-md-3 p-3 text-center text-white overflow-hidden">
					<div className="my-3 py-3">
						<h2 className="display-5">Exporting</h2>
						<p className="lead">Then export your first draft to Microsoft Word to revise, edit, and publish.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="Exporting"
							src="/images/Exporting.png"
							width="100%"
						/>
					</div>
				</div>
			</div>

			<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
				<div className="bg-dark mr-md-3 p-3 text-center text-white overflow-hidden">
					<div className="my-3 py-3">
						<h2 className="display-5">Suggestions</h2>
						<p className="lead">In every description field, you can use the inbuilt dictionary to get suggestions as you type.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="Suggestions"
							src="/images/Suggestions.png"
							width="100%"
						/>
					</div>
				</div>
				<div className="bg-light mr-md-3 p-3 text-center overflow-hidden">
					<div className="my-3 p-3">
						<h2 className="display-5">Your Own Dictionary</h2>
						<p className="lead">Or create a suggestion dictionary using your own words. Upload your writing and get suggestions for the words you use most often.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="Dictionary"
							src="/images/Dictionary.png"
							width="100%"
						/>
					</div>
				</div>
			</div>

			<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
				<div className="bg-light mr-md-3 p-3 text-center overflow-hidden">
					<div className="my-3 p-3">
						<h2 className="display-5">AI Co-Author</h2>
						<p className="lead">The AI Co-Author is coming soon.</p>
					</div>
					<div
						className="bg-light box-shadow mx-auto"
						style={{ width: 750, }}
					>
						<img
							alt="AI Co-Author"
							src="/images/FirstDrafting.png"
							width="100%"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
