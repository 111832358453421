import React from "react";

/**
 * The welcome screen for the system
 */
export const LegalScreen = () => {
	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
				<div className="col-md-5 p-lg-5 mx-auto my-5">
					<h1 className="display-5 font-weight-normal pb-3">Legal Stuff</h1>
					<p>
						Any works created with Story Breaker are the property of the user, and no ownership is transferred to the author of this software. All copyrights of works
						created using this software remain with the user.
					</p>
					<p>
						Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"),
						to deal in the Software without restriction, including without limitation the rights to use, copy, and/or distribute copies of the Software,
						and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
					</p>
					<p>
						THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
						FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
						WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
					</p>
				</div>
				<div className="product-device box-shadow d-none d-md-block" />
				<div className="product-device product-device-2 box-shadow d-none d-md-block" />
			</div>
		</>
	);
};
