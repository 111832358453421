import React from "react";

/**
 * The welcome screen for the system
 */
export const AboutScreen = () => {
	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
				<div className="col-md-5 p-lg-5 mx-auto my-5">
					<h1 className="display-5 font-weight-normal pb-3">Story Breaker</h1>
					<p>
						Story Breaker was created by me, Simon Cantan, a science-fiction and fantasy author, as a way of world building and outlining stories.
					</p>
					<p>
						I got tired of using an endless string of Word documents, Excel spreadsheets, and image folders and wanted a tool for bringing all that together. So
						I created Story Breaker, to help me and other authors create stories quicker, easier, and hopefully better.
					</p>
					<p>
						Since Story Breaker uses JSON (text) files on your computer and isn't web-based, it can be used offline and not least, it's free. The best place to locate
						files is in a cloud storage folder, but the choice to use one, or not is completely up to you.
					</p>
				</div>
				<div className="product-device box-shadow d-none d-md-block" />
				<div className="product-device product-device-2 box-shadow d-none d-md-block" />
			</div>
		</>
	);
};
