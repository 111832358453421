import React, { useEffect } from "react";

/**
 * Lets the user download the app.
 */
export const SiteLightningScreen = () => {
	const [ latestVersion, setLatestVersion ] = React.useState<any>(null);

	useEffect(() => {
		loadLatestDownload();
	}, []);

	/**
	 * Gets the latest download of the app.
	 */
	async function loadLatestDownload () {
		const response = await fetch("products/site-lightning/current-release.json")
			.then((r) => r.json());

		setLatestVersion(response);
	}

	if (!latestVersion) {
		return (
			<i className="fas fa-spinner fa-spin fa-2x fa-fw my-2" />
		);
	}

	const {
		versionNumber,
	} = latestVersion;

	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-1 m-md-3 text-center bg-light">
				<div className="col-md-5 p-lg-5 mx-auto my-5">
					<h1 className="display-3 font-weight-normal">Site Lightning</h1>
					<h1 className="display-5 font-weight-normal">Tell Your Story as an Author</h1>
					<p className="lead font-weight-normal">
						A cheap and simple way to create a stylish author website.
					</p>
					<p>
						Site Lightning lets you create an author website that you can upload to any web host. The website is created based
						on configuration files that you can edit in a text editor. This means you can create a website that is unique to you.
					</p>
					<p>
						The created website uses affiliate links to Amazon, to help pay for the development.
					</p>
					<p className="lead font-weight-normal">Click the link below to download Site Lightning.</p>
					<p className="lead font-weight-normal">Version {versionNumber}</p>
					<a href={"products/site-lightning/site-lightning-setup.zip"}>
						Downloadable Zip File
					</a>
					<br />
					<br />
					<small>
						Site Lightning is currently in beta. Please let me know if you find any bugs by using the feedback link in the header.
					</small>
				</div>
				<div className="product-device box-shadow d-none d-md-block" />
				<div className="product-device product-device-2 box-shadow d-none d-md-block" />
			</div>

		</>
	);
};
