import React from "react";
import YoutubeEmbed from "../../Components/YouTubeEmbed";

/**
 * The welcome screen for the system
 */
export const TutorialScreen = () => {
	return (
		<>
			<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
				<div className="col-md-5 p-lg-5 mx-auto my-5">
					<h1 className="display-5 font-weight-normal pb-3">Tutorial Videos</h1>
					<p>
						Click on any of the videos below to watch a tutorial on how to use Story Breaker.
					</p>
					<p>
						<h5 className="display-10 font-weight-normal pb-1">Getting Started</h5>
						<YoutubeEmbed embedId="jTrrOTFSZL0" />
					</p>
				</div>
			</div>
		</>
	);
};
