// import { useMsal } from "@azure/msal-react";
import React from "react";
import { Link } from "react-router-dom";

/**
 * The site header for navigation.
 */
export const SiteHeader = () => {
	// const { instance, } = useMsal();

	return (
		<nav className="site-header sticky-top py-1">
			<div className="container d-flex flex-column flex-md-row justify-content-between">
				<Link
					to="/"
				>
					<img
						className="logo my-2"
						src="/images/Logo.png"
						alt="Logo"
					/>
				</Link>
				<Link
					to="/"
				>
					<span className="py-2 d-none d-md-inline-block">Home</span>
				</Link>
				<Link
					to="/about"
				>
					<span className="py-2 d-none d-md-inline-block">About</span>
				</Link>
				<Link
					to="/tutorial"
				>
					<span className="py-2 d-none d-md-inline-block">Tutorials</span>
				</Link>
				<Link
					to="/legal"
				>
					<span className="py-2 d-none d-md-inline-block">Legal</span>
				</Link>
				<Link
					to="/storybreaker"
				>
					<span className="py-2 d-none d-md-inline-block">Story Breaker</span>
				</Link>
				<Link
					to="/sitelightning"
				>
					<span className="py-2 d-none d-md-inline-block">Site Lightning</span>
				</Link>
				<Link
					to="/donate"
				>
					<span className="py-2 d-none d-md-inline-block">Donate</span>
				</Link>
				<a
					href="mailto:simon.cantan@gmail.com"
				>
					<span className="py-2 d-none d-md-inline-block">Feedback</span>
				</a>
			</div>
		</nav>
	);
};
